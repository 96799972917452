.glider-slide {
  min-width: unset !important;
}

::-webkit-scrollbar {
  display: none;
  width: 0px;
}

.glider-container {
  position: relative;
  overflow: hidden;

  .glider-track {
    padding-left: 8px;
  }
}

@-moz-document url-prefix() {
  .glider-track {
    margin-bottom: 17px;
  }
  .glider-container {
    overflow: hidden;
  }
}
