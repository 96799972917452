.story-item-new {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  -webkit-tap-highlight-color: transparent;
  cursor: pointer;
  &:hover {
    .image-item-new > div > div{
      transition: transform .15s linear, filter .15s linear;
      transform: scale(1.03);
      filter: brightness(.9);
    }
  }
} 

/* Image */
.story-item-new .image-item-new {
  position: absolute !important;
}

.image-item-new > div > div {
  background-size: cover;
}
.story-image {
  width: 100%;
  height: 100%;
  position: absolute !important;
  transform: rotate(0) !important;
  background: grey;
}

.image-round {
  border-radius: 50%;
}
.image-round > div {
  border-radius: 50%;
}
.image-square-round {
  border-radius: 8px;
}
.image-square {
  border-radius: 1px;
}

.story-image > div > div {
  background-size: cover;
}

.overlay-position {
  position: absolute;
  top: 4px;
  right: 0;
  bottom: 0;
  left: 4px;
  z-index: 1;
}

.ring-wrapper {
  position: relative;
}

.ring-wrapper svg {
  fill: none;
  stroke-width: 2;
  stroke-dasharray: 0;
  stroke-dashoffset: 0;
  /* animation: stroke-draw 6s ease-out infinite alternate; */
  overflow: visible;
}

.ring-wrapper-loading-circle svg {
  animation: stroke-draw-circle 6s ease-out infinite alternate;
}

@keyframes stroke-draw-circle {
  from {
    stroke: #8a3ab9;
    stroke-dasharray: 0;
  }
  to {
    stroke: #cd486b;
    transform: rotate(180deg);
    stroke-dasharray: 8;
  }
}

.ring-wrapper-loading-square svg {
  animation: stroke-draw-square 6s ease-out infinite alternate;
}

@keyframes stroke-draw-square {
  from {
    stroke: #8a3ab9;
    stroke-dasharray: 1;
  }
  to {
    stroke: #cd486b;
    stroke-dasharray: 8;
  }
}

.has-seen {
  animation: 'none'!important;
}
/* End Image */

/* Text */
.text-item-new {
  text-align: center;
  z-index: 2;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  left: 50%;
  transform: translateX(-50%);
  display: inline;
}

.text-item-new.rtl {
  direction: rtl;
  right: 0; /* right 0 instead of direction rtl to not break mixed-text (heb + english) structure */
}
/* End Text */
