.glider-slide {
  min-width: unset !important; }

::-webkit-scrollbar {
  display: none;
  width: 0px; }

.glider-container {
  position: relative;
  overflow: hidden; }
  .glider-container .glider-track {
    padding-left: 8px; }

@-moz-document url-prefix() {
  .glider-track {
    margin-bottom: 17px; }
  .glider-container {
    overflow: hidden; } }

.story-item-new {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  -webkit-tap-highlight-color: transparent;
  cursor: pointer; }
  .story-item-new:hover .image-item-new > div > div {
    transition: -webkit-transform .15s linear, -webkit-filter .15s linear;
    transition: transform .15s linear, filter .15s linear;
    transition: transform .15s linear, filter .15s linear, -webkit-transform .15s linear, -webkit-filter .15s linear;
    -webkit-transform: scale(1.03);
            transform: scale(1.03);
    -webkit-filter: brightness(0.9);
            filter: brightness(0.9); }

/* Image */
.story-item-new .image-item-new {
  position: absolute !important; }

.image-item-new > div > div {
  background-size: cover; }

.story-image {
  width: 100%;
  height: 100%;
  position: absolute !important;
  -webkit-transform: rotate(0) !important;
          transform: rotate(0) !important;
  background: grey; }

.image-round {
  border-radius: 50%; }

.image-round > div {
  border-radius: 50%; }

.image-square-round {
  border-radius: 8px; }

.image-square {
  border-radius: 1px; }

.story-image > div > div {
  background-size: cover; }

.overlay-position {
  position: absolute;
  top: 4px;
  right: 0;
  bottom: 0;
  left: 4px;
  z-index: 1; }

.ring-wrapper {
  position: relative; }

.ring-wrapper svg {
  fill: none;
  stroke-width: 2;
  stroke-dasharray: 0;
  stroke-dashoffset: 0;
  /* animation: stroke-draw 6s ease-out infinite alternate; */
  overflow: visible; }

.ring-wrapper-loading-circle svg {
  -webkit-animation: stroke-draw-circle 6s ease-out infinite alternate;
          animation: stroke-draw-circle 6s ease-out infinite alternate; }

@-webkit-keyframes stroke-draw-circle {
  from {
    stroke: #8a3ab9;
    stroke-dasharray: 0; }
  to {
    stroke: #cd486b;
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
    stroke-dasharray: 8; } }

@keyframes stroke-draw-circle {
  from {
    stroke: #8a3ab9;
    stroke-dasharray: 0; }
  to {
    stroke: #cd486b;
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
    stroke-dasharray: 8; } }

.ring-wrapper-loading-square svg {
  -webkit-animation: stroke-draw-square 6s ease-out infinite alternate;
          animation: stroke-draw-square 6s ease-out infinite alternate; }

@-webkit-keyframes stroke-draw-square {
  from {
    stroke: #8a3ab9;
    stroke-dasharray: 1; }
  to {
    stroke: #cd486b;
    stroke-dasharray: 8; } }

@keyframes stroke-draw-square {
  from {
    stroke: #8a3ab9;
    stroke-dasharray: 1; }
  to {
    stroke: #cd486b;
    stroke-dasharray: 8; } }

.has-seen {
  -webkit-animation: "none" !important;
          animation: "none" !important; }

/* End Image */
/* Text */
.text-item-new {
  text-align: center;
  z-index: 2;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  display: inline; }

.text-item-new.rtl {
  direction: rtl;
  right: 0;
  /* right 0 instead of direction rtl to not break mixed-text (heb + english) structure */ }

/* End Text */

.strip-element .header-title {
  margin-right: 4px;
}

.powered-by {
  display: flex;
  margin-left: 4px;
  margin-right: 4px;
}

.header-mobile .powered-by a {
  height: 7px;
  width: 7px;
}
.powered-by a {
  border: 5px solid white;
  border-radius: 50%;
  background-color: white;
  height: 14px;
  width: 14px;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
}

.powered-by p {
  white-space: nowrap;
  margin: 0px 6px 0px 0px;
  opacity: 0.61;
}

.powered-by p {
  margin: 0px 4px 0px 0px;
}

.header-mobile {
  margin: 0px;
}

.powered-by img {
  vertical-align: bottom;
  margin-top: 1px;
}

.strip-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.strip-header {
  z-index: 1;
}

.strip-header .powered-by {
  font-family: Lato;
  font-weight: 700;
  font-style: italic;
  color: #9f9f9f;
  font-size: 12px;
  align-items: center;
}

.strip-header.header-mobile .powered-by {
  font-size: 10px;
}

.header-mobile .image-logo {
  height: 9px;
  margin-top: -1px;
}

.image-logo {
  height: 12px;
}

.strip-element {
  overflow: hidden;
  background: #fff0;
  position: absolute;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}

.glider {
  cursor: default !important;
}

.hidden {
  display: none;
}

.preview .glider-slide {
  pointer-events: none;
}

body:not(:hover) .glider-next,
body:not(:hover) .glider-prev {
  opacity: 0;
}

.glider-next {
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  width: 30px;
  height: 30px;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.3);
  border-radius: 30px;
  background-color: white;
  opacity: 1;
  background-image: url(https://strip.apester.com/static/media/arrow-right.40a1b827.svg);
  background-size: 13px;
  cursor: pointer;
  right: -10px;
  position: absolute;
  background-repeat: no-repeat;
  font-family: sans-serif;
  background-position: center;
}

.glider-prev {
  width: 30px;
  height: 30px;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.3);
  border-radius: 30px;
  background-color: white;
  opacity: 1;
  background-image: url(https://strip.apester.com/static/media/arrow-right.40a1b827.svg);
  background-size: 13px;
  -webkit-transform: scaleX(-1) rotateZ(0) translateY(-50%);
          transform: scaleX(-1) rotateZ(0) translateY(-50%);
  -ms-filter: 'FlipH';
  -webkit-filter: FlipH;
          filter: FlipH;
  cursor: pointer;
  left: -9px;
  position: absolute;
  background-repeat: no-repeat;
  font-family: sans-serif;
  background-position: center;
}

.strip-element-mobile {
  overflow: hidden;
}

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

.App {
  text-align: center;
}

