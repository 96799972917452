.strip-element {
  overflow: hidden;
  background: #fff0;
  position: absolute;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}

.glider {
  cursor: default !important;
}

.hidden {
  display: none;
}

.preview .glider-slide {
  pointer-events: none;
}

body:not(:hover) .glider-next,
body:not(:hover) .glider-prev {
  opacity: 0;
}

.glider-next {
  transform: translateY(-50%);
  width: 30px;
  height: 30px;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.3);
  border-radius: 30px;
  background-color: white;
  opacity: 1;
  background-image: url('../../assets/images/arrow-right.svg');
  background-size: 13px;
  cursor: pointer;
  right: -10px;
  position: absolute;
  background-repeat: no-repeat;
  font-family: sans-serif;
  background-position: center;
}

.glider-prev {
  width: 30px;
  height: 30px;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.3);
  border-radius: 30px;
  background-color: white;
  opacity: 1;
  background-image: url('../../assets/images/arrow-right.svg');
  background-size: 13px;
  transform: scaleX(-1) rotateZ(0) translateY(-50%);
  -ms-filter: 'FlipH';
  filter: FlipH;
  cursor: pointer;
  left: -9px;
  position: absolute;
  background-repeat: no-repeat;
  font-family: sans-serif;
  background-position: center;
}

.strip-element-mobile {
  overflow: hidden;
}
