.strip-element .header-title {
  margin-right: 4px;
}

.powered-by {
  display: flex;
  margin-left: 4px;
  margin-right: 4px;
}

.header-mobile .powered-by a {
  height: 7px;
  width: 7px;
}
.powered-by a {
  border: 5px solid white;
  border-radius: 50%;
  background-color: white;
  height: 14px;
  width: 14px;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
}

.powered-by p {
  white-space: nowrap;
  margin: 0px 6px 0px 0px;
  opacity: 0.61;
}

.powered-by p {
  margin: 0px 4px 0px 0px;
}

.header-mobile {
  margin: 0px;
}

.powered-by img {
  vertical-align: bottom;
  margin-top: 1px;
}

.strip-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.strip-header {
  z-index: 1;
}

.strip-header .powered-by {
  font-family: Lato;
  font-weight: 700;
  font-style: italic;
  color: #9f9f9f;
  font-size: 12px;
  align-items: center;
}

.strip-header.header-mobile .powered-by {
  font-size: 10px;
}

.header-mobile .image-logo {
  height: 9px;
  margin-top: -1px;
}

.image-logo {
  height: 12px;
}
